<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title></v-btn>
      <v-btn v-if="getAccess(`${m.accessName}.create`)" color="primary" @click="createNew()" title="Создать элемент">
        <btn-title icon="far fa-plus-square">Создать</btn-title>
      </v-btn>
    </template>
    <portal to="v-main">
      <SupplyDialog
        v-model="showEditDialog"
        v-if="showEditDialog"
        :m="configH.m"
        :configForm="{ head: configH.head, title: configH.title, api: configH.api }"
        :id="configH.id"
        :opt="configH.opt"
        :initData="configH.initData || {}"
        :params="configH"
      />
    </portal>
    <a-table-f-api ref="table" :api="url" :model="model" :useQuery="true" :defaults="defaults" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
import mainView from "./mainView";
import { getAccess } from "@/components/mixings";
export default {
  mixins: [mainView, getAccess],
  components: {
    SupplyDialog: () => import("./dialogs/supplyDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.supply,
      configH: null,
      statusItems: [],
      defaults: {
        //filters: { status: 1 },
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = this.m.title;
  },
  computed: {
    configHead() {
      return this.configRole; //this.m.config.default || {};
    },
    url() {
      return this.m.api;
    },
    model() {
      let model = JSON.parse(JSON.stringify(this.m.list));
      model.forEach(el => {
        if (el?.sourceList) {
          el.options = this.m[el.sourceList];
        }
      });
      return model;
    },
    configRole() {
      let config;
      if (this.m.config[`role_${this.$root.profile.role}`]) config = this.m.config[`role_${this.$root.profile.role}`] || {};
      else config = this.m.config.default || {};
      return config;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
      //this.$router.push({ name: "clients_view", params: { id: d.row.id } });
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      const config = {};
      this.configH = null;
      config.head = this.configRole;
      config.title = this.configRole.title;
      config.api = this.api;
      config.id = id ? id : null;
      //config.fieldsRO = "shop_id";
      let opt = [];
      if (!this.getAccess("supply.create")) opt.push("readonly");
      if (this.getAccess("supply.delete")) opt.push("delete");
      config.opt = opt.join(",");
      //config.initData = { vendor_id: this.id, status: 1 };
      config.m = this.m;
      this.configH = config;
      this.showEditDialog = true;

      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
